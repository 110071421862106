$(".question-form__soc").click(function () {
	$(".question-form__soc").removeClass("active"), $(this).addClass("active"), $(this).parent().parent().find(".sposob").val($(this).data("sposob"));
}),
	$(".callback-modal__soc").click(function () {
		$(".callback-modal__soc").removeClass("active"), $(this).addClass("active"), $(this).parent().parent().find(".sposob1").val($(this).data("sposob1"));
	}),
	$(".callback-modal__time").click(function () {
		$(".callback-modal__time").removeClass("active"), $(this).addClass("active"), $(this).parent().parent().find(".sposob2").val($(this).data("sposob2"));
	}),
	$(".now").click(function () {
		$(".timeCollapse").removeClass("show");
	});





var swiper = new Swiper(".photo-slider", {
	pagination: {
		el: ".swiper-pagination",


	},
	loop: true,
	slidesPerView: 1,
	centeredSlides: true,
	spaceBetween: 60,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},



	breakpoints: {
		767.98: {
			spaceBetween: 20,
		},

		1200: {
			spaceBetween: 40,
		},

		1500: {
			spaceBetween: 60,
		},
	}
});
